// Modal_kybAssessment.js
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Box, Select, MenuItem, InputLabel, FormControl, Typography, Grid, Autocomplete } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal_KycUpdate from './Modal_KycUpdate'; // Adjust the path as necessary
import translations from './config/translations.json';
import { clientSectorCategoryOptions } from './config/businessCategories';
import { incorporationCountryOptions } from './config/countries';
import {
  howClientGetToKnowOptions,
  howWasClientMetOptions,
  whereIsClientIncorporatedOptions,
  yesNoOptions,
  annualVolumeOptions
} from './config/formOptions';
import { t } from './Util_format';

const Modal_kybAssessment = ({ isOpen, onClose, business, onOpenNextModal }) => {
  const [isKycUpdateOpen, setIsKycUpdateOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});

  // Initialize state variables with saved data
  const savedData = business.saved_data || {};

  const [generalInfo, setGeneralInfo] = useState({
    how_client_get_to_know_paynovate: savedData.general_information_how_client_get_to_know_paynovate || '',
    how_was_the_client_met: savedData.general_information_how_was_the_client_met || '',
    what_is_the_client_sector_category: savedData.general_information_what_is_the_client_sector_category || '',
    is_there_a_clear_view_of_the_client_business_activity: savedData.general_information_is_there_a_clear_view_of_the_client_business_activity || '',
    does_business_activity_make_sense_for_the_account: savedData.general_information_does_business_activity_make_sense_for_the_account || '',
    where_is_the_client_incorporated: savedData.general_information_where_is_the_client_incorporated || '',
    incorporation_country_name: savedData.general_information_incorporation_country_name || '',
    sanctions: savedData.general_information_sanctions || '',
  });

  const [sourceOfFinancing, setSourceOfFinancing] = useState({
    adverse_media: savedData.source_of_financing_adverse_media || '',
    has_client_provided_audited_statements: savedData.source_of_financing_has_client_provided_audited_statements || '',
    does_sow_sof_make_sense: savedData.source_of_financing_does_sow_sof_make_sense || '',
    is_sow_sof_identifiable: savedData.source_of_financing_is_sow_sof_identifiable || '',
    funds_from_reputable_institution: savedData.source_of_financing_funds_from_reputable_institution || '',
    funds_from_personal_accounts: savedData.source_of_financing_funds_from_personal_accounts || '',
    funds_from_third_parties: savedData.source_of_financing_funds_from_third_parties || '',
  });

  const [verification, setVerification] = useState({
    has_client_provided_proof_of_identification: savedData.verification_has_client_provided_proof_of_identification || '',
    have_you_confirmed_authenticity_of_certifier: savedData.verification_have_you_confirmed_authenticity_of_certifier || '',
    was_client_hesitant_in_providing_documents: savedData.verification_was_client_hesitant_in_providing_documents || '',
    sanctions_screening_provided_positive_hit: savedData.verification_sanctions_screening_provided_positive_hit || '',
  });

  const [ubo, setUbo] = useState({
    pep: savedData.ubo_pep || '',
    sanctions: savedData.ubo_sanctions || '',
    adverse_media: savedData.ubo_adverse_media || '',
    residing_in_sanctioned_country: savedData.ubo_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.ubo_residing_in_high_risk_country || '',
  });

  const [directors, setDirectors] = useState({
    pep: savedData.directors_pep || '',
    sanctions: savedData.directors_sanctions || '',
    adverse_media: savedData.directors_adverse_media || '',
    residing_in_sanctioned_country: savedData.directors_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.directors_residing_in_high_risk_country || '',
  });

  const [transactions, setTransactions] = useState({
    did_client_provide_expected_monthly_inwards_volume: savedData.transactions_did_client_provide_expected_monthly_inwards_volume || '',
    expected_annual_incoming_volume: savedData.transactions_expected_annual_incoming_volume || '',
    did_client_provide_expected_monthly_outwards_volume: savedData.transactions_customer_provided_expected_monthly_outwards_volume || '',
    expected_annual_outgoing_volume: savedData.transactions_expected_annual_outgoing_volume || '',
    did_client_provide_5_counterparties_inwards: savedData.transactions_customer_provided_5_counterparties_inwards || '',
    did_client_provide_5_counterparties_outwards: savedData.transactions_customer_provided_5_counterparties_outwards || '',
    is_client_involved_in_cash_dominant_turnover: savedData.transactions_is_client_involved_in_cash_dominant_turnover || '',
    is_transaction_risk_of_money_laundering: savedData.transactions_is_transaction_risk_of_money_laundering || '',
  });

  // Update state when business prop changes
  React.useEffect(() => {
    const savedData = business.saved_data || {};

    setGeneralInfo({
      how_client_get_to_know_paynovate: savedData.general_information_how_client_get_to_know_paynovate || '',
      how_was_the_client_met: savedData.general_information_how_was_the_client_met || '',
      what_is_the_client_sector_category: savedData.general_information_what_is_the_client_sector_category || '',
      is_there_a_clear_view_of_the_client_business_activity: savedData.general_information_is_there_a_clear_view_of_the_client_business_activity || '',
      does_business_activity_make_sense_for_the_account: savedData.general_information_does_business_activity_make_sense_for_the_account || '',
      where_is_the_client_incorporated: savedData.general_information_where_is_the_client_incorporated || '',
      incorporation_country_name: savedData.general_information_incorporation_country_name || '',
      sanctions: savedData.general_information_sanctions || '',
    });

    setSourceOfFinancing({
      adverse_media: savedData.source_of_financing_adverse_media || '',
      has_client_provided_audited_statements: savedData.source_of_financing_has_client_provided_audited_statements || '',
      does_sow_sof_make_sense: savedData.source_of_financing_does_sow_sof_make_sense || '',
      is_sow_sof_identifiable: savedData.source_of_financing_is_sow_sof_identifiable || '',
      funds_from_reputable_institution: savedData.source_of_financing_funds_from_reputable_institution || '',
      funds_from_personal_accounts: savedData.source_of_financing_funds_from_personal_accounts || '',
      funds_from_third_parties: savedData.source_of_financing_funds_from_third_parties || '',
    });

    setVerification({
      has_client_provided_proof_of_identification: savedData.verification_has_client_provided_proof_of_identification || '',
      have_you_confirmed_authenticity_of_certifier: savedData.verification_have_you_confirmed_authenticity_of_certifier || '',
      was_client_hesitant_in_providing_documents: savedData.verification_was_client_hesitant_in_providing_documents || '',
      sanctions_screening_provided_positive_hit: savedData.verification_sanctions_screening_provided_positive_hit || '',
    });

    setUbo({
      pep: savedData.ubo_pep || '',
      sanctions: savedData.ubo_sanctions || '',
      adverse_media: savedData.ubo_adverse_media || '',
      residing_in_sanctioned_country: savedData.ubo_residing_in_sanctioned_country || '',
      residing_in_high_risk_country: savedData.ubo_residing_in_high_risk_country || '',
    });

    setDirectors({
      pep: savedData.directors_pep || '',
      sanctions: savedData.directors_sanctions || '',
      adverse_media: savedData.directors_adverse_media || '',
      residing_in_sanctioned_country: savedData.directors_residing_in_sanctioned_country || '',
      residing_in_high_risk_country: savedData.directors_residing_in_high_risk_country || '',
    });

    setTransactions({
      did_client_provide_expected_monthly_inwards_volume: savedData.transactions_did_client_provide_expected_monthly_inwards_volume || '',
      expected_annual_incoming_volume: savedData.transactions_expected_annual_incoming_volume || '',
      did_client_provide_expected_monthly_outwards_volume: savedData.transactions_customer_provided_expected_monthly_outwards_volume || '',
      expected_annual_outgoing_volume: savedData.transactions_expected_annual_outgoing_volume || '',
      did_client_provide_5_counterparties_inwards: savedData.transactions_customer_provided_5_counterparties_inwards || '',
      did_client_provide_5_counterparties_outwards: savedData.transactions_customer_provided_5_counterparties_outwards || '',
      is_client_involved_in_cash_dominant_turnover: savedData.transactions_is_client_involved_in_cash_dominant_turnover || '',
      is_transaction_risk_of_money_laundering: savedData.transactions_is_transaction_risk_of_money_laundering || '',
    });
  }, [business]);

  // Handle form submission
  const handleSubmit = () => {
    const assessmentData = {
      general_information_how_client_get_to_know_paynovate: generalInfo.how_client_get_to_know_paynovate,
      general_information_how_was_the_client_met: generalInfo.how_was_the_client_met,
      general_information_what_is_the_client_sector_category: generalInfo.what_is_the_client_sector_category,
      general_information_is_there_a_clear_view_of_the_client_business_activity: generalInfo.is_there_a_clear_view_of_the_client_business_activity,
      general_information_does_business_activity_make_sense_for_the_account: generalInfo.does_business_activity_make_sense_for_the_account,
      general_information_where_is_the_client_incorporated: generalInfo.where_is_the_client_incorporated,
      general_information_incorporation_country_name: generalInfo.incorporation_country_name,
      general_information_sanctions: generalInfo.sanctions,

      source_of_financing_adverse_media: sourceOfFinancing.adverse_media,
      source_of_financing_has_client_provided_audited_statements: sourceOfFinancing.has_client_provided_audited_statements,
      source_of_financing_does_sow_sof_make_sense: sourceOfFinancing.does_sow_sof_make_sense,
      source_of_financing_is_sow_sof_identifiable: sourceOfFinancing.is_sow_sof_identifiable,
      source_of_financing_funds_from_reputable_institution: sourceOfFinancing.funds_from_reputable_institution,
      source_of_financing_funds_from_personal_accounts: sourceOfFinancing.funds_from_personal_accounts,
      source_of_financing_funds_from_third_parties: sourceOfFinancing.funds_from_third_parties,

      verification_has_client_provided_proof_of_identification: verification.has_client_provided_proof_of_identification,
      verification_have_you_confirmed_authenticity_of_certifier: verification.have_you_confirmed_authenticity_of_certifier,
      verification_was_client_hesitant_in_providing_documents: verification.was_client_hesitant_in_providing_documents,
      verification_sanctions_screening_provided_positive_hit: verification.sanctions_screening_provided_positive_hit,

      ubo_pep: ubo.pep,
      ubo_sanctions: ubo.sanctions,
      ubo_adverse_media: ubo.adverse_media,
      ubo_residing_in_sanctioned_country: ubo.residing_in_sanctioned_country,
      ubo_residing_in_high_risk_country: ubo.residing_in_high_risk_country,

      directors_pep: directors.pep,
      directors_sanctions: directors.sanctions,
      directors_adverse_media: directors.adverse_media,
      directors_residing_in_sanctioned_country: directors.residing_in_sanctioned_country,
      directors_residing_in_high_risk_country: directors.residing_in_high_risk_country,

      transactions_did_client_provide_expected_monthly_inwards_volume: transactions.did_client_provide_expected_monthly_inwards_volume,
      transactions_expected_annual_incoming_volume: transactions.expected_annual_incoming_volume,
      transactions_customer_provided_expected_monthly_outwards_volume: transactions.did_client_provide_expected_monthly_outwards_volume,
      transactions_expected_annual_outgoing_volume: transactions.expected_annual_outgoing_volume,
      transactions_customer_provided_5_counterparties_inwards: transactions.did_client_provide_5_counterparties_inwards,
      transactions_customer_provided_5_counterparties_outwards: transactions.did_client_provide_5_counterparties_outwards,
      transactions_is_client_involved_in_cash_dominant_turnover: transactions.is_client_involved_in_cash_dominant_turnover,
      transactions_is_transaction_risk_of_money_laundering: transactions.is_transaction_risk_of_money_laundering,
    };


    setAssessmentData(assessmentData);
    console.log('Assessment Data:', assessmentData);

    if (onOpenNextModal) {
      const response = onOpenNextModal(assessmentData);
      if (response?.status === "error") {
        if (response?.details === "All beneficiaries must have a KYC status of 100") {
          alert("Problema con il kyc Direttori, contattare amministratore");
          return;
        }
        if (response?.fdt_error_code === "UNKNOWN_ENTITY_TYPE") {
          alert("La tipologia di entità selezionata non è al momento supportata. La preghiamo di contattare il servizio clienti per maggiori informazioni.");
          return;
        }
      }
    }

    setIsKycUpdateOpen(true);
  };


  const handleCloseBothModals = () => {
    setIsKycUpdateOpen(false); // Close the second modal
    onClose(); // Close the first modal
  };


  // Update state when business prop changes
  React.useEffect(() => {
    const savedData = business.saved_data || {};

    setGeneralInfo({
      how_client_get_to_know_paynovate: savedData.general_information_how_client_get_to_know_paynovate || '',
      how_was_the_client_met: savedData.general_information_how_was_the_client_met || '',
      what_is_the_client_sector_category: savedData.general_information_what_is_the_client_sector_category || '',
      is_there_a_clear_view_of_the_client_business_activity: savedData.general_information_is_there_a_clear_view_of_the_client_business_activity || '',
      does_business_activity_make_sense_for_the_account: savedData.general_information_does_business_activity_make_sense_for_the_account || '',
      where_is_the_client_incorporated: savedData.general_information_where_is_the_client_incorporated || '',
      incorporation_country_name: savedData.general_information_incorporation_country_name || '',
      sanctions: savedData.general_information_sanctions || '',
    });

    setSourceOfFinancing({
      adverse_media: savedData.source_of_financing_adverse_media || '',
      has_client_provided_audited_statements: savedData.source_of_financing_has_client_provided_audited_statements || '',
      does_sow_sof_make_sense: savedData.source_of_financing_does_sow_sof_make_sense || '',
      is_sow_sof_identifiable: savedData.source_of_financing_is_sow_sof_identifiable || '',
      funds_from_reputable_institution: savedData.source_of_financing_funds_from_reputable_institution || '',
      funds_from_personal_accounts: savedData.source_of_financing_funds_from_personal_accounts || '',
      funds_from_third_parties: savedData.source_of_financing_funds_from_third_parties || '',
    });

    setVerification({
      has_client_provided_proof_of_identification: savedData.verification_has_client_provided_proof_of_identification || '',
      have_you_confirmed_authenticity_of_certifier: savedData.verification_have_you_confirmed_authenticity_of_certifier || '',
      was_client_hesitant_in_providing_documents: savedData.verification_was_client_hesitant_in_providing_documents || '',
      sanctions_screening_provided_positive_hit: savedData.verification_sanctions_screening_provided_positive_hit || '',
    });

    setUbo({
      pep: savedData.ubo_pep || '',
      sanctions: savedData.ubo_sanctions || '',
      adverse_media: savedData.ubo_adverse_media || '',
      residing_in_sanctioned_country: savedData.ubo_residing_in_sanctioned_country || '',
      residing_in_high_risk_country: savedData.ubo_residing_in_high_risk_country || '',
    });

    setDirectors({
      pep: savedData.directors_pep || '',
      sanctions: savedData.directors_sanctions || '',
      adverse_media: savedData.directors_adverse_media || '',
      residing_in_sanctioned_country: savedData.directors_residing_in_sanctioned_country || '',
      residing_in_high_risk_country: savedData.directors_residing_in_high_risk_country || '',
    });

    setTransactions({
      did_client_provide_expected_monthly_inwards_volume: savedData.transactions_did_client_provide_expected_monthly_inwards_volume || '',
      expected_annual_incoming_volume: savedData.transactions_expected_annual_incoming_volume || '',
      did_client_provide_expected_monthly_outwards_volume: savedData.transactions_customer_provided_expected_monthly_outwards_volume || '',
      expected_annual_outgoing_volume: savedData.transactions_expected_annual_outgoing_volume || '',
      did_client_provide_5_counterparties_inwards: savedData.transactions_customer_provided_5_counterparties_inwards || '',
      did_client_provide_5_counterparties_outwards: savedData.transactions_customer_provided_5_counterparties_outwards || '',
      is_client_involved_in_cash_dominant_turnover: savedData.transactions_is_client_involved_in_cash_dominant_turnover || '',
      is_transaction_risk_of_money_laundering: savedData.transactions_is_transaction_risk_of_money_laundering || '',
    });
  }, [business]);


  // Options for the dropdowns


  // Render the modal
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      fullScreen
    >
      <Box sx={{ bgcolor: '#2196f3', color: 'white', textAlign: 'center', position: 'relative' }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: 'absolute', left: 8, top: 8 }}
        >
          <ArrowBackIcon sx={{ fontSize: 40 }} />
        </IconButton>
        <DialogTitle> Business Assessment</DialogTitle>
      </Box>

      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: '20px', padding: '20px' }}>
          {/* General Information Section */}
          {/* General Information Section */}
          <Grid item xs={12}>
            <Typography variant="h6">General Information</Typography>
          </Grid>

          {/* How did the client get to know about Paynovate? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq1')}</InputLabel>
              <Select
                value={generalInfo.how_client_get_to_know_paynovate}
                onChange={(e) => setGeneralInfo({ ...generalInfo, how_client_get_to_know_paynovate: e.target.value })}
                label={t('Kybq1')}
              >
                {howClientGetToKnowOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* How was the client met? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq2')}</InputLabel>
              <Select
                value={generalInfo.how_was_the_client_met}
                onChange={(e) => setGeneralInfo({ ...generalInfo, how_was_the_client_met: e.target.value })}
                label={t('Kybq2')}
              >
                {howWasClientMetOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* What is the client sector category according to our merchant acceptance policy? */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={clientSectorCategoryOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Kybq3')}
                  variant="outlined"
                />
              )}
              value={
                clientSectorCategoryOptions.find(option => option.value === generalInfo.what_is_the_client_sector_category) || null
              }
              onChange={(event, newValue) => {
                setGeneralInfo({ ...generalInfo, what_is_the_client_sector_category: newValue ? newValue.value : '' });
              }}
            />
          </Grid>

          {/* Is there a clear view of the client's business activity? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq4')}</InputLabel>
              <Select
                value={generalInfo.is_there_a_clear_view_of_the_client_business_activity}
                onChange={(e) => setGeneralInfo({ ...generalInfo, is_there_a_clear_view_of_the_client_business_activity: e.target.value })}
                label={t('Kybq4')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Does this business activity make sense with the purpose of the account requested? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq5')}</InputLabel>
              <Select
                value={generalInfo.does_business_activity_make_sense_for_the_account}
                onChange={(e) => setGeneralInfo({ ...generalInfo, does_business_activity_make_sense_for_the_account: e.target.value })}
                label={t('Kybq5')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Where is the client incorporated? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq6')}</InputLabel>
              <Select
                value={generalInfo.where_is_the_client_incorporated}
                onChange={(e) => setGeneralInfo({ ...generalInfo, where_is_the_client_incorporated: e.target.value })}
                label={t('Kybq6')}
              >
                {whereIsClientIncorporatedOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Incorporation country name */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={incorporationCountryOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Kybq7')}
                  variant="outlined"
                />
              )}
              value={
                incorporationCountryOptions.find(option => option.value === generalInfo.incorporation_country_name) || null
              }
              onChange={(event, newValue) => {
                setGeneralInfo({ ...generalInfo, incorporation_country_name: newValue ? newValue.value : '' });
              }}
            />
          </Grid>




          {/* Sanctions */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq8')}</InputLabel>
              <Select
                value={generalInfo.sanctions}
                onChange={(e) => setGeneralInfo({ ...generalInfo, sanctions: e.target.value })}
                label={t('Kybq8')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Adverse media */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq9')}</InputLabel>
              <Select
                value={sourceOfFinancing.adverse_media}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, adverse_media: e.target.value })}
                label={t('Kybq9')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          {/* Source of Financing Section */}
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="h6">Source of Financing</Typography>
          </Grid>

          {/* Has the client provided management accounts or audited financial statements? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq10')}</InputLabel>
              <Select
                value={sourceOfFinancing.has_client_provided_audited_statements}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, has_client_provided_audited_statements: e.target.value })}
                label={t('Kybq10')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          {/* Does the stated SoW/SoF make sense with what you know about the client? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq11')}</InputLabel>
              <Select
                value={sourceOfFinancing.does_sow_sof_make_sense}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, does_sow_sof_make_sense: e.target.value })}
                label={t('Kybq11')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Is the SoW/SoF clearly identifiable? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq12')}</InputLabel>
              <Select
                value={sourceOfFinancing.is_sow_sof_identifiable}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, is_sow_sof_identifiable: e.target.value })}
                label={t('Kybq12')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          {/* Are the funds coming from a recognised reputable financial/credit institution? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq13')}</InputLabel>
              <Select
                value={sourceOfFinancing.funds_from_reputable_institution}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, funds_from_reputable_institution: e.target.value })}
                label={t('Kybq13')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Are the funds coming from personal accounts? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq14')}</InputLabel>
              <Select
                value={sourceOfFinancing.funds_from_personal_accounts}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, funds_from_personal_accounts: e.target.value })}
                label={t('Kybq14')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Are any of the funds coming from unrelated third parties? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq15')}</InputLabel>
              <Select
                value={sourceOfFinancing.funds_from_third_parties}
                onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, funds_from_third_parties: e.target.value })}
                label={t('Kybq15')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Verification Section */}
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="h6">Verification</Typography>
          </Grid>

          {/* Has the client provided acceptable proof of identification and proof of address? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq16')}</InputLabel>
              <Select
                value={verification.has_client_provided_proof_of_identification}
                onChange={(e) => setVerification({ ...verification, has_client_provided_proof_of_identification: e.target.value })}
                label={t('Kybq16')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Have you been able to confirm the authenticity/professional status of the certifier? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq17')}</InputLabel>
              <Select
                value={verification.have_you_confirmed_authenticity_of_certifier}
                onChange={(e) => setVerification({ ...verification, have_you_confirmed_authenticity_of_certifier: e.target.value })}
                label={t('Kybq17')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Was the client hesitant to provide the requested documentation? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq18')}</InputLabel>
              <Select
                value={verification.was_client_hesitant_in_providing_documents}
                onChange={(e) => setVerification({ ...verification, was_client_hesitant_in_providing_documents: e.target.value })}
                label={t('Kybq18')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Has the sanctions screening tool provided any related positive hit? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq19')}</InputLabel>
              <Select
                value={verification.sanctions_screening_provided_positive_hit}
                onChange={(e) => setVerification({ ...verification, sanctions_screening_provided_positive_hit: e.target.value })}
                label={t('Kybq19')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* UBO Section */}
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="h6">UBO (Ultimate Beneficial Owner)</Typography>
          </Grid>

          {/* PEP */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq20')}</InputLabel>
              <Select
                value={ubo.pep}
                onChange={(e) => setUbo({ ...ubo, pep: e.target.value })}
                label={t('Kybq20')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Sanctions */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq8')}</InputLabel>
              <Select
                value={ubo.sanctions}
                onChange={(e) => setUbo({ ...ubo, sanctions: e.target.value })}
                label={t('Kybq8')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Adverse media */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq9')}</InputLabel>
              <Select
                value={ubo.adverse_media}
                onChange={(e) => setUbo({ ...ubo, adverse_media: e.target.value })}
                label={t('Kybq9')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Residing in a sanctioned country */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq21')}</InputLabel>
              <Select
                value={ubo.residing_in_sanctioned_country}
                onChange={(e) => setUbo({ ...ubo, residing_in_sanctioned_country: e.target.value })}
                label={t('Kybq21')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Residing in a high risk country */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq22')}</InputLabel>
              <Select
                value={ubo.residing_in_high_risk_country}
                onChange={(e) => setUbo({ ...ubo, residing_in_high_risk_country: e.target.value })}
                label={t('Kybq22')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          {/* Directors Section */}
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="h6">Directors</Typography>
          </Grid>

          {/* PEP */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq20')}</InputLabel>
              <Select
                value={directors.pep}
                onChange={(e) => setDirectors({ ...directors, pep: e.target.value })}
                label={t('Kybq20')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          {/* Sanctions */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq8')}</InputLabel>
              <Select
                value={directors.sanctions}
                onChange={(e) => setDirectors({ ...directors, sanctions: e.target.value })}
                label={t('Kybq8')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Adverse media */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq9')}</InputLabel>
              <Select
                value={directors.adverse_media}
                onChange={(e) => setDirectors({ ...directors, adverse_media: e.target.value })}
                label={t('Kybq9')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Residing in a sanctioned country */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq21')}</InputLabel>
              <Select
                value={directors.residing_in_sanctioned_country}
                onChange={(e) => setDirectors({ ...directors, residing_in_sanctioned_country: e.target.value })}
                label={t('Kybq21')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Residing in a high risk country */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq22')}</InputLabel>
              <Select
                value={directors.residing_in_high_risk_country}
                onChange={(e) => setDirectors({ ...directors, residing_in_high_risk_country: e.target.value })}
                label={t('Kybq22')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>



          {/* Transactions Section */}
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Typography variant="h6">Transactions</Typography>
          </Grid>

          {/* Did the client provide us with the expected total monthly inwards volume? */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Kybq23')}</InputLabel>
              <Select
                value={transactions.did_client_provide_expected_monthly_inwards_volume}
                onChange={(e) => setTransactions({ ...transactions, did_client_provide_expected_monthly_inwards_volume: e.target.value })}
                label={t('Kybq23')}
              >
                {yesNoOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

{/* Expected total incoming volume in EUR per annum */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq24')}</InputLabel>
    <Select
      value={transactions.expected_annual_incoming_volume}
      onChange={(e) => setTransactions({ ...transactions, expected_annual_incoming_volume: e.target.value })}
      label={t('Kybq24')}
    >
      {annualVolumeOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

{/* Did the client provide us with the expected total monthly outwards volume? */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq25')}</InputLabel>
    <Select
      value={transactions.did_client_provide_expected_monthly_outwards_volume}
      onChange={(e) => setTransactions({ ...transactions, did_client_provide_expected_monthly_outwards_volume: e.target.value })}
      label={t('Kybq25')}
    >
      {yesNoOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>


{/* Expected total outgoing volume in EUR per annum */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq26')}</InputLabel>
    <Select
      value={transactions.expected_annual_outgoing_volume}
      onChange={(e) => setTransactions({ ...transactions, expected_annual_outgoing_volume: e.target.value })}
      label={t('Kybq26')}
    >
      {annualVolumeOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

{/* Did the client provide us with the 5 counterparties they expect to transact inwards with the most? */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq27')}</InputLabel>
    <Select
      value={transactions.did_client_provide_5_counterparties_inwards}
      onChange={(e) => setTransactions({ ...transactions, did_client_provide_5_counterparties_inwards: e.target.value })}
      label={t('Kybq27')}
    >
      {yesNoOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

{/* Did the client provide us with the 5 counterparties they expect to transact outwards with the most? */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq28')}</InputLabel>
    <Select
      value={transactions.did_client_provide_5_counterparties_outwards}
      onChange={(e) => setTransactions({ ...transactions, did_client_provide_5_counterparties_outwards: e.target.value })}
      label={t('Kybq28')}
    >
      {yesNoOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>


       {/* Is the client involved in a cash dominant turnover business? */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq29')}</InputLabel>
    <Select
      value={transactions.is_client_involved_in_cash_dominant_turnover}
      onChange={(e) => setTransactions({ ...transactions, is_client_involved_in_cash_dominant_turnover: e.target.value })}
      label={t('Kybq29')}
    >
      {yesNoOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

          {/* Could the transactions be used for money laundering? */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>{t('Kybq30')}</InputLabel>
    <Select
      value={transactions.is_transaction_risk_of_money_laundering}
      onChange={(e) => setTransactions({ ...transactions, is_transaction_risk_of_money_laundering: e.target.value })}
      label={t('Kybq30')}
    >
      {yesNoOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>{t(`Kyba_${option.value}`)}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

        </Grid>
      </DialogContent>
      <Box textAlign='center' m={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          sx={{ mr: 2 }}
        >
          Annulla e Chiudi
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Continua
        </Button>
      </Box>
      <Modal_KycUpdate
        isOpen={isKycUpdateOpen}
        onClose={handleCloseBothModals} // Pass the function to close both modals
        assessmentData={assessmentData}
        business={business}
      />
    </Dialog>
  );
};


export default Modal_kybAssessment;
