export const yesNoOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];
  
  export const howClientGetToKnowOptions = [
    { label: 'Existing Group', value: 'existing_group' },
    { label: 'Internet Search', value: 'internet_search' },
    { label: 'Social Media', value: 'social_media' },
    { label: 'Known to Company', value: 'known_to_company' },
    { label: 'Sales Team', value: 'sales_team' },
  ];
  
 
  export const howWasClientMetOptions = [
      { value: 'face_to_face', label: 'Face to Face' },
      { value: 'remotely', label: 'Remotely' },
    ];
  
  
  export const whereIsClientIncorporatedOptions = [
    { label: 'Local', value: 'local' },
    { label: 'EU', value: 'eu' },
    { label: 'Non-EU', value: 'non_eu' },
  ];

  export const annualVolumeOptions = [
    { value: 'less_than_1M_euros', label: 'Less than 1M Euros' },
    { value: '1M_to_2M_euros', label: '1M to 2M Euros' },
    { value: '2M_to_15M_euros', label: '2M to 15M Euros' },
    { value: 'more_than_15M_euros', label: 'More than 15M Euros' },
  ];