export const clientSectorCategoryOptions = [
    { label: 'Alcohol Sales', value: 'alcohol_sales' },
    { label: 'Drugs and Tools Specifically Intended for the Production of Drugs, Drug Paraphernalia, Illegal Drugs, Substances Designed to Mimic Illegal Drugs and/or Other Psychoactive Products', value: 'drugs_and_tools_specifically_intended_for_the_production_of_drugs_drug_paraphernalia_illegal_drugs_substances_designed_to_mimic_illegal_drugs_and_or_other_psychoactive_products' },
    { label: 'Illegal Products or Services or Any Service Providing Peripheral Support of Illegal Activities Including Illegal Gambling Services', value: 'illegal_products_or_services_or_any_service_providing_peripheral_support_of_illegal_activities_including_illegal_gambling_services' },
    { label: 'Legal Gambling or Game of Skill Where Participants Receive Cash or Cash Equivalents Prizes with Material Value Including but Not Limited to Casinos, Poker, Bingo, Slot Machines, Betting, Lotteries, Racing, Fantasy Sports', value: 'legal_gambling_or_game_of_skill_where_participants_receive_cash_or_cash_equivalents_orizes_with_of_material_value_including_but_not_limited_to_casinos_poker_bingo_slot_machines_betting_lotteries_racing_fantasy_sports' },
    { label: 'Products or Services Specifically Offered or Intended to be Used to Create, Produce, or Grow Drugs or Drug Ingredients', value: 'products_or_services_specifically_offered_or_intended_to_be_used_to_create_produce_or_grow_drugs_or_drug_ingredients' },
    { label: 'Pharmaceuticals Prescription Medicine and Medical Devices Including Animal Pharmaceuticals', value: 'pharmaceuticals_prescription_medicine_and_medical_devices_including_animal_pharmaceuticals' },
    { label: 'Tobacco Smoking Supplies E-Cigarettes E-Liquids Vaping Liquid Vaping Accessories', value: 'tobacco_smoking_supplies_e_cigarettes_e_liquids_vaping_liquid_vaping_accessories' },
    { label: 'Trade of Fireworks Flammable Radioactive Materials', value: 'trade_of_fireworks_flammable_oadioactive_materials' },
    { label: 'Trade of Weapons Ammunitions Military Arms Explosive Devices and Firearm Parts', value: 'trade_of_weapons_ammunitions_military_arms_explosive_devices_and_firearm_parts' },
    { label: 'Any Type of US Based Gambling Services', value: 'any_type_of_us_based_gambling_services' },
    { label: 'Cryptocurrency Exchange', value: 'cryptocurrency_exchange' },
    { label: 'High Risk Securities Such as Contract for Difference (CFD), Financial Spread Betting, Initial Coin Offering (ICO), Forex, Currency Options, Cryptocurrency Options Trading and Purchases', value: 'high_risk_securities_such_as_contract_for_difference_cfd_financial_spread_betting_initial_coin_offering_ico_forex_currency_options_cryptocurrency_options_trading_and_purchases' },
    { label: 'Security Brokers Investments of Any Kind Including the Purchase of Securities, Currencies, Derivatives, Commodities, Shares, Options, Precious Metals, Other Financial Instruments', value: 'security_brokers_investments_of_any_kind_including_the_purchase_of_securities_currencies_derivatives_commodities_shares_options_previous_metals_other_financial_instruments' },
    { label: 'Decryption and Descrambler Products and Services Devices Designed to Block Jam or Interfere with Cellular and Personal Communications', value: 'decryption_and_descrambleroducts_and_services_devices_designed_to_block_jam_or_interfere_with_cellular_and_personal_communications' },
    { label: 'Specific Cosmetic Products that Claims Extreme Result Anti-Wrinkle Skin Repair etc.', value: 'specific_cosmetic_products_that_claims_extreme_result_anti_wrinkle_skin_repair_etc_' },
    { label: 'Provision of IT Services', value: 'provision_of_it_services' },
    { label: 'Acquisition or Business Investment Services', value: 'acquisition_or_business_investment_services' },
    { label: 'Regulated Payment Services', value: 'regulated_payment_services' },
    { label: 'Unregulated Payment Services', value: 'unregulated_payment_services' },
    { label: 'Consultancy Services', value: 'consultancy_services' },
    { label: 'Holding Entity for Onboarded Trading Entity', value: 'holding_entity_for_onboarded_trading_entity' },
    { label: 'Holding Entity without an Onboarded Trading Entity', value: 'holding_entity_without_an_onboarded_trading_entity' },
    { label: 'Gambling Casino Sites for Operational Day to Day Transactions and PSP Settlements', value: 'gambling_casino_sites_for_operational_day_to_day_transactions_and_psp_settlements' },
    { label: 'Gambling Casino Sites for Direct Player Payments Deposits and Withdrawals', value: 'gambling_casino_sites_for_direct_player_payments_deposits_and_withdrawals' },
    { label: 'Wholesale of Flowers and Plants', value: 'wholesale_of_flowers_and_plants' },
    { label: 'Wholesale of Fruit and Vegetables', value: 'wholesale_of_fruit_and_vegetables' },
    { label: 'Wholesale of Meat and Meat Products', value: 'wholesale_of_meat_and_meat_products' },
    { label: 'Wholesale of Dairy Products Eggs and Edible Oils and Fats', value: 'wholesale_of_dairy_products_eggs_and_edible_oils_and_fats' },
    { label: 'Wholesale of Fruit and Vegetable Juices Mineral Water and Soft Drinks', value: 'wholesale_of_fruit_and_vegetable_juices_mineral_water_and_soft_drinks' },
    { label: 'Wholesale of Sugar and Chocolate and Sugar Confectionery', value: 'wholesale_of_sugar_and_chocolate_and_sugar_confectionery' },
    { label: 'Wholesale of Coffee Tea Cocoa and Spices', value: 'wholesale_of_coffee_tea_cocoa_and_spices' },
    { label: 'Wholesale of Other Food Including Fish Crustaceans and Molluscs', value: 'wholesale_of_other_food_including_fish_crustaceans_and_molluscs' },
    { label: 'Wholesale of Textiles', value: 'wholesale_of_textiles' },
    { label: 'Wholesale of Clothing and Footwear', value: 'wholesale_of_clothing_and_footwear' },
    { label: 'Wholesale of Audio Tapes Records CDs and Video Tapes and the Equipment on which These are Played', value: 'wholesale_of_audio_tapes_records_cds_and_video_tapes_and_the_equipment_on_which_these_are_played' },
    { label: 'Wholesale of Radio Television Goods Electrical Household Appliances Other than Records Tapes CDs Video Tapes and the Equipment Used for Playing Them', value: 'wholesale_of_radio_television_goods_electrical_household_appliances_other_than_records_tapes_cds_video_tapes_and_the_equipment_used_for_playing_them' },
    { label: 'Wholesale of Perfume and Cosmetics', value: 'wholesale_of_perfume_and_cosmetics' },
    { label: 'Wholesale of Office Furniture', value: 'wholesale_of_office_furniture' },
    { label: 'Retail Sale of Fruit and Vegetables in Specialised Stores', value: 'retail_sale_of_fruit_and_vegetables_in_specialised_stores' },
    { label: 'Retail Sale of Meat and Meat Products in Specialised Stores', value: 'retail_sale_of_meat_and_meat_products_in_specialised_stores' },
    { label: 'Retail Sale of Fish Crustaceans and Molluscs in Specialised Stores', value: 'retail_sale_of_fish_crustaceans_and_molluscs_in_specialised_stores' },
    { label: 'Retail Sale of Bread Cakes Flour Confectionery and Sugar Confectionery in Specialised Stores', value: 'retail_sale_of_bread_cakes_flour_confectionery_and_sugar_confectionery_in_specialised_stores' },
    { label: 'Retail Sale of Beverages in Specialised Stores', value: 'retail_sale_of_beverages_in_specialised_stores' },
    { label: 'Other Retail Sale of Food in Specialised Stores', value: 'other_retail_sale_of_food_in_specialised_stores' },
    { label: 'Retail Sale of Computers Peripheral Units and Software in Specialised Stores', value: 'retail_sale_of_computers_peripheral_units_and_software_in_specialised_stores' },
    { label: 'Retail Sale of Mobile Telephones', value: 'retail_sale_of_mobile_telephones' },
    { label: 'Retail Sale of Telecommunications Equipment Other than Mobile Telephones', value: 'retail_sale_of_telecommunications_equipment_other_than_mobile_telephones' },
    { label: 'Retail Sale of Audio and Video Equipment in Specialised Stores', value: 'retail_sale_of_audio_and_video_equipment_in_specialised_stores' },
    { label: 'Retail Sale of Textiles in Specialised Stores', value: 'retail_sale_of_textiles_in_specialised_stores' },
    { label: 'Retail Sale of Carpets Rugs Wall and Floor Coverings in Specialised Stores', value: 'retail_sale_of_carpets_rugs_wall_and_floor_coverings_in_specialised_stores' },
    { label: 'Retail Sale of Electrical Household Appliances in Specialised Stores', value: 'retail_sale_of_electrical_household_appliances_in_specialised_stores' },
    { label: 'Retail Sale of Musical Instruments and Scores', value: 'retail_sale_of_musical_instruments_and_scores' },
    { label: 'Retail of Furniture Lighting and Similar Not Musical Instruments or Scores in Specialised Store', value: 'retail_of_furniture_lighting_and_similar_not_musical_instruments_or_scores_in_specialised_store' },
    { label: 'Retail Sale of Books in Specialised Stores', value: 'retail_sale_of_books_in_specialised_stores' },
    { label: 'Retail Sale of Music and Video Recordings in Specialised Stores', value: 'retail_sale_of_music_and_video_recordings_in_specialised_stores' },
    { label: 'Retail Sale of Sports Goods Fishing Gear Camping Goods Boats and Bicycles', value: 'retail_sale_of_sports_goods_fishing_gear_camping_goods_boats_and_bicycles' },
    { label: 'Retail Sale of Games and Toys in Specialised Stores', value: 'retail_sale_of_games_and_toys_in_specialised_stores' },
    { label: 'Retail Sale of Clothing in Specialised Stores', value: 'retail_sale_of_clothing_in_specialised_stores' },
    { label: 'Retail Sale of Footwear in Specialised Stores', value: 'retail_sale_of_footwear_in_specialised_stores' },
    { label: 'Retail Sale of Cosmetic and Toilet Articles in Specialised Stores', value: 'retail_sale_of_cosmetic_and_toilet_articles_in_specialised_stores' },
    { label: 'Retail Sale of Flowers Plants Seeds Fertilizers Pet Animals and Pet Food in Specialised Stores', value: 'retail_sale_of_flowers_plants_seeds_fertilizers_pet_animals_and_pet_food_in_specialised_stores' },
    { label: 'Retail Sale by Opticians', value: 'retail_sale_by_opticians' },
    { label: 'Couriers Services', value: 'couriers_services' },
    { label: 'Taxi Operation', value: 'taxi_operation' },
    { label: 'Other Passenger Land Transport', value: 'other_passenger_land_transport' },
    { label: 'Logistic and Shipping Companies', value: 'logistic_and_shipping_companies' },
    { label: 'Other Transportation Support Activities', value: 'other_transportation_support_activities' },
    { label: 'Licensed Carriers', value: 'licensed_carriers' },
    { label: 'Hotels and Similar Accommodation B&B', value: 'hotels_and_similar_accommodation_b_b' },
    { label: 'Holiday Centres and Villages', value: 'holiday_centres_and_villages' },
    { label: 'Licensed Restaurants', value: 'licenced_restaurants' },
    { label: 'Take Away Food Shops and Mobile Food Stands', value: 'take_away_food_shops_and_mobile_food_stands' },
    { label: 'Hostels', value: 'hostels' },
    { label: 'Licensed Bar', value: 'licensed_bar' },
    { label: 'Other Food Services Such as Catering Agency', value: 'other_food_services_such_as_catering_agency' },
    { label: 'Fitness', value: 'fitness' },
    { label: 'Tour Guide Companies', value: 'tourguide_companies' },
    { label: 'Cinema', value: 'cinema' },
    { label: 'Theatres', value: 'theatres' },
    { label: 'Advertising Services', value: 'advertising_services' },
    { label: 'Marketing', value: 'marketing' },
    { label: 'Advertising Agencies', value: 'advertising_agencies' },
    { label: 'Media Representation Services', value: 'media_representation_services' },
    { label: 'Construction Businesses', value: 'construction_businesses' },
    { label: 'Building Maintenance and Repairs', value: 'building_maintenance_and_repairs' },
    { label: 'Hydraulics', value: 'hydraulics' },
    { label: 'Software Companies', value: 'software_companies' },
    { label: 'Business and Domestic Software Development', value: 'business_and_domestic_software_development' },
    { label: 'Programming', value: 'programming' },
    { label: 'Electricity Companies', value: 'electricity_companies' },
    { label: 'Gas Companies', value: 'gas_companies' },
    { label: 'Water Suppliers', value: 'water_suppliers' }
]; 